@import url(//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css);
.userDropdown {
  display: none;
}

.userDropdownBtn:hover .userDropdown {
  display: block;
  display: flex;
  flex-direction: column;
  transition: all 1s;
}

.logo{
  width: auto;
  height: 50px;
}

/* Footer */
/* Old footer css */
/* 
.container-footer{
  position: fixed; 
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background-color: rgb(255, 255, 255);
  width: 100%;
}

.foot-img {
  display: block;
  margin: auto auto;
}

.foot-text {
  padding-top: 5px;
  text-align: center;
}

.foot-text:hover {
  color: red;
}

.ad {
  height: 40px;
  margin-bottom: 10px;
  text-align: center;
  background-color: pink;
} */

.userImage{
    width:60px;
    aspect-ratio: 1;
    margin: .5rem 1rem;
}
.userImage img{
    width:100%;
    height: 100%;
    border-radius: 50%;
    padding: 2px;
    border: 1px solid rgb(203, 203, 203);
    object-fit: cover;
}
.userImage{
    position: relative;
}
.userImage input{
    width: 100%;
    height: 100%;
    position: absolute;
    top: -2px;
    left: 0;
    z-index:4;
    font-size:50px ;
    opacity: 0;
}
/* Edit Image */
.edit{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 25px;
    height: 25px;
    background-color: white;
    border-radius: 50%;
}
/* @src/components/Modal.module.css */
.addUserImageModal{
    position: absolute;
    top: 10%;
    left:0;
    width:100%;
    height: 100%;
    z-index: 5;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3rem;
    background-color: rgba(0,0,0,0.4);
}
.addUserImageModal form{
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    background-color: #ff3e4e;
}
.addUserImageModal button{
    background-color: white;
    padding: 1rem;
    border-radius: 1rem;
    color: black;
}
/* Header section */
.header {
  padding-bottom: 0;
}

.slider {
  -webkit-appearance: none;
  height: 12px;
  border-radius: 5px;
  background: #d3d3d3;
  outline: none;
  transition: opacity 0.2s;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 18px;
  height: 18px;
  border-radius: 75%;
  background: #b7791f;
  cursor: pointer;
}

.slider::-moz-range-thumb {
  width: 18px;
  height: 18px;
  border-radius: 75%;
  background: #b7791f;
  cursor: pointer;
}

/*  CSS for Categories */

@media only screen and (max-width: 800px) {
  .category {
      /* padding-top: 5%;
      padding-bottom: 5%; */
      display: flex;
      overflow-x: auto;
  }

  .category::-webkit-scrollbar {
      width: 0;
  }

  .category .cat-item {
      min-width: 64px;
      line-height: 100px;
      text-align: center;
  }
  

  .cat-img {
      border-radius: 50%;
      width: 50%;
      display: block;
      margin: auto auto;
    }
  .cat-text {
    text-align: center;
    font-size: 12px;
  }
}

@media only screen and (min-width: 801px) {
  /* .category {
      padding-top: 5%;
      padding-bottom: 5%;
  } */

  .cat-img {
      border-radius: 50%;
      display: block;
      width: 50%;
      margin: auto auto;
  }
  .cat-text {
      text-align: center;
  }
}

.category {
  padding-top: 70px; 
}


.carousel-item {
  max-height: 150px;
  border-radius: 30px 30px 30px 30px;
  overflow: hidden;
  padding: 0 10px;
}

/* Featured Products */

/* .featured-product {
  height: 150px;
  width: 150px;
  margin-bottom: 100px;
} */

/* .recos {
  width: 18rem;
  padding: 50px 50px 50px 50px;
  display: inline-block;
} */

.product-header {
  display: inline-block;
  width: 100%;
}

.recommended-for-you {
  padding-left: 20px;
  float: left;
  font-weight: 500;
  font-size: large;
}

.view-all {
  padding-right: 20px;
  float: right;
}

.featured-products {
  display: inline-block;
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 10px 10px 10px 10px;
}

.featured-products::-webkit-scrollbar {
  width: 0;
}

.product-container {
  max-width: 170px;
  margin-left: 3px;
  margin-right: 3px;
  min-height: 150px;
}

.featured-img {
  padding-top: 5px;
  height: 75px;
  width: 120px
}

.featured-text {
  padding-top: 40px;
}

/* All Products */

.all-products {
  margin-top: 10px;
}


/* Product images from server */


fieldset,
label {
  margin: 0;
  padding: 0;
}

/****** Style Star Rating Widget *****/

.rating {
  border: none;
  float: left;
}

.rating > input {
  display: none;
}

.rating > label:before {
  margin: 5px;
  font-size: 2.25em;
  font-family: FontAwesome;
  display: inline-block;
  content: "\f005";
}

/* Half Rating System */

/*.rating > .half:before { 
  content: "\f089";
  position: absolute;
}
*/

/* 
<input type="radio" id="star4half" name="rating" defaultValue={4.5} /><label className="half" htmlFor="star4half" title="Pretty good - 4.5 stars" />

<input type="radio" id="star3half" name="rating" defaultValue={3.5} /><label className="half" htmlFor="star3half" title="Meh - 3.5 stars" />

<input type="radio" id="star2half" name="rating" defaultValue={3.0} /><label className="half" htmlFor="star2half" title="Kinda bad - 2.5 stars" />

<input type="radio" id="star1half" name="rating" defaultValue={2.5} /><label className="half" htmlFor="star1half" title="Meh - 1.5 stars" />

<input type="radio" id="starhalf" name="rating" defaultValue={0.5} /><label className="half" htmlFor="starhalf" title="Sucks big time - 0.5 stars" />
*/

.rating > label {
  color: #ddd;
  float: right;
}

/***** CSS Magic to Highlight Stars on Hover *****/

.rating>input:checked~label,
/* show gold star when clicked */
.rating:not(:checked)>label:hover,
/* hover current star */
.rating:not(:checked)>label:hover~label {
  color: #b7791f;
}

/* hover previous stars in list */

.rating>input:checked+label:hover,
/* hover current star when changing rating */
.rating>input:checked~label:hover,
.rating>label:hover~input:checked~label,
/* lighten current selection */
.rating>input:checked~label:hover~label {
  color: #b7791f;
}

/* css for the submenu at the top of the product description page  */

.submenu-container{
  padding-left: 40%;
  padding-top: 5%;
  padding-bottom: 5%;
}

/* css for the product details  */


/* .main-container{
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  padding-top: 110px;

} */

.main-container .big-img{
  max-width: 500px;
  min-width: 375px;
}

.big-img img{
  width: 100%;
  height: 350px;
  margin-top: -50px;
  border-radius: 10px;
  
  /* display: block; 
  object-fit: revert; */
  
}

.details .box{
  max-width: 98%;
  min-width: 290px;
  margin: 25px;
}

.box .row{
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}


.price-tag{
  padding-top: 5px;
  padding-left: 15px;
  display: inline;
  float: left;
}

.heading-tag{
  text-align: left;
  padding: auto 100px;
}

.description-box{
  max-width: 500px;
  min-width: 375px;
  height: 50px;
  max-height: 50px;
  margin-left: 16px;
  margin-right: 16px;
}

.sellerInfo
{
  text-align: left;
  padding-bottom: 5px;
  padding-left: 4px;
}

/* css for the reveiw section  */

.review-icon{
  border-radius: 45%;
  display: inline;
  float: right;
  padding-right: 5px;
}

.review-tag{
  display: inline;
  float: left;
  padding-left: 5px;
}

/* The Modal */

.modalBackground{
  width: 100vw;
  height: 100vh;
  background-color: rgba(red, green, blue, alpha);
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer{
  width: 50px;
  height: 50px;
  border-radius: 12px;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 8px;
}

.modalContainer .title{
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}

.modalContainer .body{
  flex: 50% 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
}

.modalContainer .footer
{
  flex: 20% 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalContainer .footer button{
  width: 10px;
  height: 10px;
  margin: 5px;
  border: none;
  background-color: cornflowerblue;
  color: white;
  border-radius: 5px;
  font-size: 10px;
}

#cancelBtn{
  background-color: red;
}

/* The Close Button */
/* .close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
} */

.reviewContainer{
  padding-top: 40px;
}

.carousel-item {
  max-height: 150px;
  border-radius: 15px;
  overflow: hidden;
  padding: 3px;
}

/* css for the featured section  */

.product-header {
  display: inline-block;
  width: 100%;
}

.recommended-for-you {
  padding-left: 20px;
  float: left;
}

.view-all {
  padding-right: 20px;
  float: right;
}

.featured-products {
  display: inline-block;
  display: flex;
  overflow-x: auto;
  padding: 10px 10px 10px 10px;
}

.featured-products::-webkit-scrollbar {
  width: 0;
}

.product-container {
  max-width: 170px;
  height: 120px;
  margin-left: 3px;
  margin-right: 3px;
}

.Featured-container{
  display: flex;
  overflow-x: scroll;
}
/* Gallery Navigation */
.navbuttons{
  position:absolute;
  top: 40%;
  z-index: 5;
}
.navbuttons.left{
  left:-2rem;
}
.navbuttons.right{
  right:-2rem;
}
/* this is the css for the first file of the productListing  */

.products {
    position: relative;
    width: 100%;
    padding-top: 110px;
}

/* this is css for the second file of the productlisting  */

.products2 {
    position: relative;
    width: 100%;
    padding-top: 20px;
}

/* this is the css of the product card  */

.container {
    display: grid;
    justify-content: center;
    border-radius: 10px;
    position: relative;
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    background-color:ghostwhite;
    overflow-x: hidden;
    overflow-y: scroll;
}

/* to make the content visible at the bottom and not get hidden by the footer */
/* .main-container {
    padding-bottom: 150px;
    margin-left: 8px;
} */

/* to give left margin */
.first-container {
    padding-bottom: 150px;
    margin-left: 6px;
}

/* css for the part where we have ad  */

.carousel
{
    padding-top: 8px;
}

/* this is for the text to describe the product */

.text{
    display: inline;
    float: left;
    font-size: small;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}
/* this is the css for the prica tag  */

.tag{
    display: inline;
    float: right;
    font-size: small;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
}

/* this is the css for the image of the product  */

.img{
    display: flex;
    justify-content: center;
}

.formContainer {
    background-color: #a7bcff;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.formContainer .formWrapper {
    background-color: white;
    padding: 20px 60px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    align-items: center;
}
.formContainer .formWrapper .logo {
    color: #5d5b8d;
    font-weight: bold;
    font-size: 24px;
}
.formContainer .formWrapper .title {
    color: #5d5b8d;
    font-size: 12px;
}
.formContainer .formWrapper form {
    display: flex;
    flex-direction: column;
    grid-gap: 15px;
    gap: 15px;
}
.formContainer .formWrapper form input {
    padding: 15px;
    border: none;
    width: 250px;
    border-bottom: 1px solid #a7bcff;
}
.formContainer .formWrapper form input::-webkit-input-placeholder {
    color: #afafaf;
}
.formContainer .formWrapper form input::placeholder {
    color: #afafaf;
}
.formContainer .formWrapper form button {
    background-color: #7b96ec;
    color: white;
    padding: 10px;
    font-weight: bold;
    border: none;
    cursor: pointer;
}
.formContainer .formWrapper form label {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    color: #8da4f1;
    font-size: 12px;
    cursor: pointer;
}
.formContainer .formWrapper form label img {
    width: 32px;
}
.formContainer .formWrapper p {
    color: #5d5b8d;
    font-size: 12px;
    margin-top: 10px;
}
.home {
    background-color: #fff;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.home .container {
    /* border: 1px solid white; */
    /* border-radius: 10px; */
    width: 65%;
    height: 82%;
    display: flex;
    overflow: hidden;
}
@media screen and (max-width: 768px) {
    .home .container {
        width: 100%;
   }
}
.home .container .sidebar {
    /* flex: 1; */
    overflow: scroll;
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: absolute;
}
.home .container .sidebar .navbar {
    display: flex;
    align-items: center;
    background-color: #2f2d52;
    height: 50px;
    padding: 10px;
    justify-content: space-between;
    color: #ddddf7;
}
.home .container .sidebar .navbar .logo {
    font-weight: bold;
}
@media screen and (max-width: 768px) {
    .home .container .sidebar .navbar .logo {
        display: none;
   }
}
.home .container .sidebar .navbar .user {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
}
.home .container .sidebar .navbar .user img {
    background-color: #ddddf7;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    object-fit: fill;
}
.home .container .sidebar .navbar .user button {
    background-color: #5d5b8d;
    color: #ddddf7;
    font-size: 10px;
    border: none;
    cursor: pointer;
}
@media screen and (max-width: 768px) {
    .home .container .sidebar .navbar .user button {
        position: absolute;
        bottom: 10px;
   }
}
.home .container .sidebar .search {
    border-bottom: 1px solid gray;
}
.home .container .sidebar .search .searchForm {
    padding: 10px;
}
.home .container .sidebar .search .searchForm input {
    background-color: transparent;
    border: none;
    color: black;
    outline: none;
}
.home .container .sidebar .search .searchForm input::-webkit-input-placeholder {
    color: lightgray;
}
.home .container .sidebar .search .searchForm input::placeholder {
    color: lightgray;
}
.home .container .sidebar .userChat {
    padding: 10px;
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    color: black;
    cursor: pointer;
    overflow: hidden;
}
.home .container .sidebar .userChat:hover {
    background-color: #2f2d52;
}
.home .container .sidebar .userChat img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
}
.home .container .sidebar .userChat .userChatInfo span {
    font-size: 18px;
    font-weight: 500;
}
.home .container .sidebar .userChat .userChatInfo p {
    font-size: 14px;
    color: gray;
}
.home .container .chat {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -1;
    /* flex: 2; */
}
.home .container .chat .chatInfo {
    height: 50px;
    /* background-color: lightgray; */
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px;
    color: #000;
}
.home .container .chat .chatIcons {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
}
.home .container .chat .chatIcons img {
    height: 24px;
    cursor: pointer;
}
.home .container .chat .messages {
    background-color: #fff;
    padding: 10px;
    height: calc(100% - 160px);
    overflow: scroll;
}
.home .container .chat .messages .message {
    display: flex;
    grid-gap: 20px;
    gap: 20px;
    margin-bottom: 20px;
}
.home .container .chat .messages .message .messageInfo {
    display: flex;
    flex-direction: column;
    color: gray;
    font-weight: 300;
}
.home .container .chat .messages .message .messageInfo img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}
.home .container .chat .messages .message .messageContent {
    max-width: 80%;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
}
.home .container .chat .messages .message .messageContent p {
    /* background-color: lightgray; */
    padding: 10px 20px;
    border-radius: 0px 10px 10px 10px;
    max-width: -webkit-max-content;
    max-width: max-content;
}
.home .container .chat .messages .message .messageContent img {
    width: 50%;
}
.home .container .chat .messages .message.owner {
    flex-direction: row-reverse;
}
.home .container .chat .messages .message.owner .messageContent {
    align-items: flex-end;
}
.home .container .chat .messages .message.owner .messageContent p {
    /* background-color: lightgray; */
    color: black;
    border-radius: 10px 0px 10px 10px;
}
.home .container .chat .input {
    height: 50px;
    /* background-color: lightgray; */
    position: absolute;
    bottom: 0;
    width: 100%;
    padding: 10px 10px 10px 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.home .container .chat .input input {
    width: 100%;
    padding: 8px;
    border: none;
    outline: none;
    color: #2f2d52;
    font-size: 18px;
}
.home .container .chat .input input::-webkit-input-placeholder {
    color: lightgray;
}
.home .container .chat .input input::placeholder {
    color: lightgray;
}
.home .container .chat .input .send {
    display: flex;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}
.home .container .chat .input .send img {
    height: 24px;
    cursor: pointer;
}
/* .home .container .chat .input .send button {
    border: none;
    padding: 10px 15px;
    color: white;
    background-color: #8da4f1;
    cursor: pointer;
} */

